<template>
	<section class="permission">
		<!--基本信息-->
		<div class="psmMk">
			<el-form :model="form" label-width="120px" :rules="formRules" ref="form">
				<el-form-item label="角色名称" prop="name">
					<el-input v-model.trim="form.name" :maxlength="10" placeholder="不超过10个字" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="权限选择">
					<el-tree
						:data="psmNode"
						show-checkbox
						node-key="id"
						:default-expand-all="true"
						:props="defaultProps"
						:indent="24"
						ref="psmNodeTree">
					</el-tree>
				</el-form-item>
				<el-form-item label="备注" prop="remark">
					<el-input v-model.trim="form.remark" :maxlength="50" placeholder="不超过50个字"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button v-if="!isEdit" type="primary" @click.native="addSubmit" :loading="Loading" icon="el-icon-circle-check">创建角色</el-button>
				<el-button v-else type="primary" @click.native="saveSubmit" :loading="Loading" icon="el-icon-circle-check">保存修改</el-button>
				<el-button @click="goBack" icon="el-icon-circle-close">取消</el-button>
			</div>
		</div>
	</section>
</template>

<script>
	import {getPsmNode, addAdminRole, viewAdminRole, saveAdminRole } from '../../api/api';
	export default {
		data() {
			return {
				//编辑ID
				aID: this.$route.query.id,
				isEdit: false,	//是否为编辑

				defaultProps: {
					children: 'children',
					label: 'name'
				},

				//权限节点选项
				psmNode: [],

				//表单验证规则
				formRules: {
					name: [
						{ required: true, message: '请输入角色名称名称', trigger: 'blur' }
					]
				},

				//创建新增
				Loading: false,
				//表单数据
				form: {
					name: '',
					menu_id: '',
					remark: ''
				},

			}
		},
		methods: {
			//获取权限节点
			getPsmNodeM() {
				getPsmNode().then((res) => {
					if(res.code == 1){
						this.psmNode = res.data;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//增加角色名称
			addSubmit: function () {
				this.getCheckedKeys()
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							this.Loading = true;
							let para = Object.assign({}, this.form);
							para.menu_id = para.menu_id.toString();
							addAdminRole(para).then((res) => {
								this.Loading = false;
								if(res.code == 1){
									this.$message({
										message: res.msg,
										type: 'success'
									});
									this.goBack()
								}else {
									this.$message({
										message: res.msg,
										type: 'error'
									});
								}

							});
						});
					}
				});
			},
			//获取信息
			getInfo() {
				viewAdminRole({id:this.aID}).then((res) => {
					if(res.code == 1){
						this.form.name = res.data.name
						this.setCheckedKeys(res.data.menu_id)
						this.form.remark = res.data.remark
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//提交角色名称基本信息
			saveSubmit: function () {
				this.getCheckedKeys()
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							this.Loading = true;
							let para = Object.assign({}, this.form);
							para.menu_id = para.menu_id.toString();
							para.id = this.aID
							saveAdminRole(para).then((res) => {
								this.Loading = false;
								if(res.code == 1){
									this.$message({
										message: res.msg,
										type: 'success'
									});
									this.goBack()
								}else {
									this.$message({
										message: res.msg,
										type: 'error'
									});
								}

							});
						});
					}
				});
			},
			//获取已选择节点
			getCheckedKeys(){
				this.form.menu_id = this.$refs.psmNodeTree.getCheckedKeys()
			},
			setCheckedKeys(val) {
				this.$refs.psmNodeTree.setCheckedKeys(val);
			},
		},
		mounted() {
			//判断是否为编辑页并获取文章信息
			if(this.aID){
				this.isEdit = true
				this.getInfo()
			}

			//获取权限节点
			this.getPsmNodeM();

		}
	}

</script>